import React, {
  Fragment,
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { FormApi } from 'final-form';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onContact, onContactReset } from 'redux/actions/app.actions';

import LayoutContainer from 'containers/LayoutContainer';
import ContactView from 'views/ContactView';
import Modal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';

import { IContactFormData } from 'views/ContactView/types';

import {
  ContactViewModalContainer,
  ContactViewModalTitle,
  ContactViewModalImage,
} from 'views/ContactView/styles';

import 'views/ContactView/i18n';

export interface IContactContainerProps {
  isFAQ?: boolean;
  isView?: boolean;
}

const ContactContainer: FunctionComponent<IContactContainerProps> = ({
  isFAQ = false,
  isView = false,
}: IContactContainerProps) => {
  const dispatch = useDispatch();
  const [isContactModalOpened, setIsContactModalOpened] = useState(false);
  const { isContactFormSucceeded, loading } = useTypedSelector((state) => state.app);
  const formRef: MutableRefObject<FormApi<IContactFormData, Partial<IContactFormData>> | null> =
    useRef(null);

  useEffect(() => {
    if (isContactFormSucceeded) {
      setIsContactModalOpened(true);
      formRef.current?.restart({ captcha: '', name: '', email: '', phone: '', comments: '' });
      dispatch(onContactReset());
    }
  }, [dispatch, isContactFormSucceeded]);

  const onSubmit = (formData: IContactFormData) => dispatch(onContact(formData));

  const Container = isView ? Fragment : LayoutContainer;

  return (
    <Container title={i18next.t<string>('CONTACT_VIEW:TITLE')}>
      <ContactView formRef={formRef} isFAQ={isFAQ} isView={isView} onSubmit={onSubmit} />
      <Modal onClose={() => setIsContactModalOpened(false)} open={isContactModalOpened}>
        <ContactViewModalContainer>
          <ContactViewModalTitle>
            {i18next.t<string>('CONTACT_VIEW:FORM_SENT_SUCCESSFULLY')}
          </ContactViewModalTitle>
          <ContactViewModalImage />
        </ContactViewModalContainer>
      </Modal>
      {loading && <Loading isModalMode message={i18next.t<string>('CONTACT_VIEW:LOADING')} />}
    </Container>
  );
};

export default ContactContainer;
