import { Reducer } from 'redux';

import { IBlogsState } from 'types/blogs.types';
import * as constants from 'redux/constants/blogs.constants';

export const defaultState: IBlogsState = {
  items: [],
  loading: false,
  selectedItem: null,
};

const blogsReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.BLOGS_ON_GET_ALL_REQUESTED:
      return {
        ...state,
        loading: !action.id,
      };
    case constants.BLOGS_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: !!action.id,
        selectedItem: null,
      };
    case constants.BLOGS_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.items,
        loading: false,
      };
    case constants.BLOGS_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selectedItem: data.item,
      };
    case constants.BLOGS_ON_GET_ALL_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.BLOGS_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
        selectedItem: null,
      };
    default:
      return state;
  }
};

export default blogsReducer;
