import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { ViewContainer, ViewContent, Title } from 'styles/view';

import './i18n';

const UsView: FunctionComponent = () => (
  <ViewContainer data-testid="us-view">
    <ViewContent>
      <Title>{i18next.t<string>('US_VIEW:TITLE')}</Title>
    </ViewContent>
  </ViewContainer>
);

export default UsView;
