import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetAll } from 'redux/actions/services.actions';
import { servicesSelector } from 'redux/selectors/services.selector';

import LayoutContainer from 'containers/LayoutContainer';
import ContactContainer from 'containers/ContactContainer';
import ServicesView from 'views/ServicesView';

import 'views/ServicesView/i18n';

const ServicesContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { loading } = useTypedSelector((state) => state.services);
  const items = useTypedSelector(servicesSelector);

  useEffect(() => {
    dispatch(onGetAll());
  }, [dispatch]);

  return (
    <LayoutContainer title={i18next.t<string>('SERVICES_VIEW:TITLE')}>
      <ServicesView items={items} loading={loading} />
      <ContactContainer isView />
    </LayoutContainer>
  );
};

export default ServicesContainer;
