import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';

import Button from 'components/commons/Button';

import { Title, ViewContent } from 'styles/view';

export const ErrorContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
`;

export const ErrorTitle = styled(Title)`
  && {
    width: auto;
    margin: 40px 0;
    font-size: 40px;
    line-height: normal;
    text-align: center;
    white-space: normal;

    @media (max-width: ${SIZES.bigPhone}px) {
      font-size: 24px;
    }
  }
`;

export const ErrorImage = styled.img`
  width: 360px;

  @media (max-width: ${SIZES.bigPhone}px) {
    width: 200px;
  }
`;

export const ErrorButton = styled(Button)`
  width: fit-content;
  height: 44px;
  padding: 12px 35px;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  border-radius: 10px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    align-self: center;
  }
`;
