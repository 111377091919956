import { createSelector } from 'reselect';

import { ASSETS_URL } from 'configs/configs';

import { IBlogsState } from 'types/blogs.types';

const getItems = (state: { blogs: IBlogsState }) => state.blogs.items;
const getSelectedItem = (state: { blogs: IBlogsState }) => state.blogs.selectedItem;

export const blogsSelector = createSelector([getItems], (blogs) =>
  blogs.map((blog) => ({
    ...blog,
    image: ASSETS_URL + blog.image,
  })),
);

export const blogSelector = createSelector(
  [getSelectedItem],
  (blog) =>
    blog && {
      ...blog,
      image: ASSETS_URL + blog.image,
    },
);
