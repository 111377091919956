import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ArticleOutlined,
  EmailOutlined,
  LiveHelpOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';

import { isMenuOpenedSelector } from 'redux/selectors/app.selector';
import { onToggleMenu } from 'redux/actions/app.actions';

import Navbar from 'components/main/Navbar';
import Sidebar from 'components/main/Sidebar';

import 'components/main/Navbar/i18n';

export interface IHeaderContainerProps {
  isMobile: boolean;
}

const HeaderContainer: FunctionComponent<IHeaderContainerProps> = (
  props: IHeaderContainerProps,
) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const isMenuOpened = useTypedSelector(isMenuOpenedSelector);
  const { pathname } = useLocation();

  const onCloseMenu = () => dispatch(onToggleMenu(false));

  const onOpenMenu = () => dispatch(onToggleMenu(true));

  useEffect(() => {
    if (isMobile && isMenuOpened) setTimeout(onCloseMenu, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const items = [
    {
      Icon: SettingsOutlined,
      key: 'services',
      label: i18next.t<string>('NAVBAR:SERVICES'),
      to: '/services',
    },
    {
      Icon: ArticleOutlined,
      key: 'blog',
      label: i18next.t<string>('NAVBAR:BLOG'),
      to: '/blog',
    },
    {
      Icon: LiveHelpOutlined,
      key: 'faq',
      label: i18next.t<string>('NAVBAR:FAQ'),
      to: '/faq',
    },
    {
      Icon: EmailOutlined,
      key: 'contact',
      label: i18next.t<string>('NAVBAR:CONTACT'),
      to: '/contact',
    },
  ];

  return (
    <>
      <Navbar items={items} onOpenMenu={onOpenMenu} />
      {isMobile && <Sidebar isMenuOpened={isMenuOpened} items={items} onCloseMenu={onCloseMenu} />}
    </>
  );
};

export default HeaderContainer;
