import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOGS_VIEW', {
  TITLE: 'Blog',
  KNOW_OUR_ARTICLES_TITLE: 'Know our articles',
  EXPLORE_MORE_CONTENT_TITLE: 'Explore more content',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOGS_VIEW', {
  TITLE: 'Blog',
  KNOW_OUR_ARTICLES_TITLE: 'Conocé nuestros artículos',
  EXPLORE_MORE_CONTENT_TITLE: 'Explorar más contenido',
});
