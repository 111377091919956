import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'FIELDS', {
  DATE: 'Date',
  EMAIL: 'Email address',
  PASSWORD: 'Password',
  PHONE_NUMBER: 'Phone number',
});

i18next.addResources(locales.EN, 'VALIDATIONS', {
  FIELD_NOT_MATCH: '{{ field }} does not match',
  INVALID_FIELD: 'Invalid {{ field }}',
  INVALID_DATE: 'Please choose a date & time that is in the future for scheduling',
  INVALID_FIELD_LENGTH: '{{ field }} must be at least {{ length }} characters',
  INVALID_FIELD_SPECIAL_CHARACTERS:
    '{{ field }} requires at least 1 uppercase character, numbers and symbol',
  INVALID_FILE_TYPE: 'Invalid file type, please try again with .jpg, .jpeg, .png or .svg',
  REQUIRED_FIELD: 'Required',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'FIELDS', {
  DATE: 'Fecha',
  EMAIL: 'Email',
  PASSWORD: 'Contraseña',
  PHONE_NUMBER: 'Número de teléfono',
});

i18next.addResources(locales.ES, 'VALIDATIONS', {
  FIELD_NOT_MATCH: '{{ field }} no coincide',
  INVALID_FIELD: '{{ field }} inválido',
  INVALID_DATE: 'Elija una fecha y hora futura',
  INVALID_FIELD_LENGTH: '{{ field }} tiene que tener al menos {{ length }} caracteres',
  INVALID_FIELD_SPECIAL_CHARACTERS:
    '{{ field }} requiere al menos 1 caracter en mayúscula, números y algún símbolo',
  INVALID_FILE_TYPE:
    'Tipo de archivo inválido, por favor vuelva a intentarlo con .jpg, .jpeg, .png o .svg',
  REQUIRED_FIELD: 'Requerido',
});
