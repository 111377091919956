import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Author from 'components/commons/Author';

import { IBlogPreviewProps } from './types';
import {
  BlogPreviewContainer,
  BlogPreviewImage,
  BlogPreviewInfoContainer,
  BlogPreviewTitle,
  BlogPreviewIntroduction,
  BlogPreviewVisits,
} from './styles';

import './i18n';

const BlogPreview: FunctionComponent<IBlogPreviewProps> = (props: IBlogPreviewProps) => {
  const { isLarge = false, isReversed = false, item } = props;

  return (
    <BlogPreviewContainer isLarge={isLarge} isReversed={isReversed} to={`/blog/${item.id}`}>
      <BlogPreviewImage isLarge={isLarge} isReversed={isReversed} src={item.image} />
      <BlogPreviewInfoContainer>
        <Author date={item.createdAt} name={item.author} />
        <BlogPreviewTitle isLarge={isLarge}>{item.title}</BlogPreviewTitle>
        <BlogPreviewIntroduction>&quot;{item.introduction}</BlogPreviewIntroduction>
        <BlogPreviewVisits>
          {item.visits} {i18next.t<string>('BLOG_PREVIEW:VISIT') + (item.visits !== 1 ? 's' : '')}
        </BlogPreviewVisits>
      </BlogPreviewInfoContainer>
    </BlogPreviewContainer>
  );
};

export default BlogPreview;
