import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'HOME_VIEW', {
  TITLE: 'Home',
  TEMPORARY_RENTAL_TITLE: 'We manage your temporary rental',
  TEMPORARY_RENTAL_SUBTITLE: 'Save time and maximize your income.',
  TEMPORARY_RENTAL_BUTTON: '¡Contact us!',
  WHAT_WE_OFFER_TITLE: 'What we offer?',
  WHAT_WE_OFFER_PHOTOGRAPHY_TITLE: 'Professional photography',
  WHAT_WE_OFFER_PHOTOGRAPHY_DESCRIPTION:
    'Attract more guests and provide an accurate and attractive representation of the property.',
  WHAT_WE_OFFER_PUBLISH_TITLE: 'We publish your property',
  WHAT_WE_OFFER_PUBLISH_DESCRIPTION:
    'Creating engaging posts is an art. We will publish your property on Airbnb, Booking, Expedia and VRBO.',
  WHAT_WE_OFFER_ATENTION_TITLE: 'Guest service 24/7',
  WHAT_WE_OFFER_ATENTION_DESCRIPTION:
    'From handing over the keys to fixing a water leak, we take care of it all.',
  WHAT_WE_OFFER_MAINTENANCE_TITLE: 'Maintenance',
  WHAT_WE_OFFER_MAINTENANCE_DESCRIPTION:
    'Proactive control of 50 points between stays. We take care of your property as if it were ours.',
  WHAT_WE_OFFER_OPTIMIZATION_TITLE: 'Optimization',
  WHAT_WE_OFFER_OPTIMIZATION_DESCRIPTION:
    'We optimize the pricing of your property to increase the occupancy rate and your income.',
  WHAT_WE_OFFER_BUTTON: 'Know more',
  PUBLISH_YOUR_PROPERTY_TITLE: 'We publish your apartment in',
  EXPLORE_CONTENT_TITLE: 'Explore content',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'HOME_VIEW', {
  TITLE: 'Inicio',
  TEMPORARY_RENTAL_TITLE: 'Administramos tu alquiler temporario',
  TEMPORARY_RENTAL_SUBTITLE: 'Ahorrá tiempo y maximizá tus ingresos.',
  TEMPORARY_RENTAL_BUTTON: '¡Contactanos!',
  WHAT_WE_OFFER_TITLE: '¿Qué ofrecemos?',
  WHAT_WE_OFFER_PHOTOGRAPHY_TITLE: 'Fotografía profesional',
  WHAT_WE_OFFER_PHOTOGRAPHY_DESCRIPTION:
    'Atraé a más huéspedes y proporcioná una representación precisa y atractiva de la propiedad.',
  WHAT_WE_OFFER_PUBLISH_TITLE: 'Publicamos tu propiedad',
  WHAT_WE_OFFER_PUBLISH_DESCRIPTION:
    'Armar publicaciones atractivas es un arte. Publicaremos tu propiedad en Airbnb, Booking, Expedia y VRBO.',
  WHAT_WE_OFFER_ATENTION_TITLE: 'Atención al huésped 24/7',
  WHAT_WE_OFFER_ATENTION_DESCRIPTION:
    'Desde entregar las llaves hasta arreglar una pérdida de agua, nos encargamos de todo.',
  WHAT_WE_OFFER_MAINTENANCE_TITLE: 'Mantenimiento',
  WHAT_WE_OFFER_MAINTENANCE_DESCRIPTION:
    'Control proactivo de 50 puntos entre estadías. Cuidamos tu propiedad como si fuera nuestra.',
  WHAT_WE_OFFER_OPTIMIZATION_TITLE: 'Optimización',
  WHAT_WE_OFFER_OPTIMIZATION_DESCRIPTION:
    'Optimizamos el pricing de tu propiedad para incrementar la tasa de ocupación y tus ingresos.',
  WHAT_WE_OFFER_BUTTON: 'Conocer más',
  PUBLISH_YOUR_PROPERTY_TITLE: 'Publicamos tu departamento en',
  EXPLORE_CONTENT_TITLE: 'Explorar contenido',
});
