import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';
import { COLORS } from 'helpers/enums/colors';

export const ViewContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 20px;
`;

export const ViewContent = styled.div`
  width: 100%;
  max-width: ${SIZES.bigDesktop}px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    max-width: ${SIZES.smallDesktop}px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  color: ${COLORS.blueLuckyPoint};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${SIZES.bigTablet}px) {
    text-align: center;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 24px;
  }
`;
