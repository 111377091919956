import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOG_PREVIEW', {
  VISIT: 'visit',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOG_PREVIEW', {
  VISIT: 'visita',
});
