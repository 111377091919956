import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { history } from 'helpers/history';

import Carousel from 'components/commons/Carousel';
import BlogPreview from 'components/commons/BlogPreview';

import People from 'views/HomeView/assets/people.png';
import { ReactComponent as Airbnb } from 'views/HomeView/assets/airbnb.svg';
import { ReactComponent as Expedia } from 'views/HomeView/assets/expedia.svg';
import { ReactComponent as Booking } from 'views/HomeView/assets/booking.svg';
import { ReactComponent as Vrbo } from 'views/HomeView/assets/vrbo.svg';

import { IBlog } from 'types/blogs.types';
import { ViewContainer } from 'styles/view';

import { IHomeViewProps } from './types';
import {
  TemporaryRentalContainer,
  TemporaryRentalTextContainer,
  TemporaryRentalTitle,
  TemporaryRentalSubtitle,
  TemporaryRentalButton,
  TemporaryRentalPeople,
  WhatWeOfferContainer,
  WhatWeOfferTitle,
  WhatWeOfferImagesContainer,
  WhatWeOfferImageContainer,
  WhatWeOfferImage,
  WhatWeOfferImageTitle,
  WhatWeOfferImageDescription,
  WhatWeOfferButton,
  WhatWeOfferArrow,
  PublishYourPropertyContainer,
  PublishYourPropertyContent,
  PublishYourPropertyTitle,
  PublishYourPropertyCompanyContainer,
  PublishYourPropertyCompany,
  ExploreContentContainer,
  ExploreContentTitle,
  ExploreContentBlogContainer,
} from './styles';

import './i18n';

const HomeView: FunctionComponent<IHomeViewProps> = ({ blogs, offers }: IHomeViewProps) => (
  <ViewContainer data-testid="home-view">
    <TemporaryRentalContainer>
      <TemporaryRentalTextContainer>
        <TemporaryRentalTitle>
          {i18next.t<string>('HOME_VIEW:TEMPORARY_RENTAL_TITLE')}
        </TemporaryRentalTitle>
        <TemporaryRentalSubtitle>
          {i18next.t<string>('HOME_VIEW:TEMPORARY_RENTAL_SUBTITLE')}
        </TemporaryRentalSubtitle>
        <TemporaryRentalButton onClick={() => history.push('/contact')} variant="outlined">
          {i18next.t<string>('HOME_VIEW:TEMPORARY_RENTAL_BUTTON')}
        </TemporaryRentalButton>
      </TemporaryRentalTextContainer>
      <TemporaryRentalPeople alt="people" src={People} />
    </TemporaryRentalContainer>
    <WhatWeOfferContainer>
      <WhatWeOfferTitle>{i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_TITLE')}</WhatWeOfferTitle>
      <WhatWeOfferImagesContainer>
        {offers.map(({ description, image, key, title }) => (
          <WhatWeOfferImageContainer
            key={`service-${key}`}
            onClick={() => history.push('/services', { key })}
          >
            <WhatWeOfferImage alt={title} src={image} />
            <WhatWeOfferImageTitle>{title}</WhatWeOfferImageTitle>
            <WhatWeOfferImageDescription>{description}</WhatWeOfferImageDescription>
          </WhatWeOfferImageContainer>
        ))}
      </WhatWeOfferImagesContainer>
      <WhatWeOfferButton onClick={() => history.push('/services')} variant="outlined">
        {i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_BUTTON')}
        <WhatWeOfferArrow />
      </WhatWeOfferButton>
    </WhatWeOfferContainer>
    <PublishYourPropertyContainer>
      <PublishYourPropertyContent>
        <PublishYourPropertyTitle>
          {i18next.t<string>('HOME_VIEW:PUBLISH_YOUR_PROPERTY_TITLE')}
        </PublishYourPropertyTitle>
        <PublishYourPropertyCompanyContainer>
          <PublishYourPropertyCompany>
            <Airbnb />
          </PublishYourPropertyCompany>
          <PublishYourPropertyCompany>
            <Expedia />
          </PublishYourPropertyCompany>
          <PublishYourPropertyCompany>
            <Booking />
          </PublishYourPropertyCompany>
          <PublishYourPropertyCompany>
            <Vrbo />
          </PublishYourPropertyCompany>
        </PublishYourPropertyCompanyContainer>
      </PublishYourPropertyContent>
    </PublishYourPropertyContainer>
    <ExploreContentContainer>
      <ExploreContentTitle>
        {i18next.t<string>('HOME_VIEW:EXPLORE_CONTENT_TITLE')}
      </ExploreContentTitle>
      <ExploreContentBlogContainer>
        <Carousel
          autoPlay
          items={blogs}
          renderItem={(item: IBlog) => <BlogPreview item={item} />}
        />
      </ExploreContentBlogContainer>
    </ExploreContentContainer>
  </ViewContainer>
);

export default HomeView;
