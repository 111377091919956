import React, { FunctionComponent, useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';

import useWindowSize from 'hooks/useWindowSize';
import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as LeftArrow } from 'components/commons/Carousel/assets/left.svg';
import { ReactComponent as RightArrow } from 'components/commons/Carousel/assets/right.svg';

import { ICarouselProps } from './types';
import { CarouselContainer, CarouselLeftArrow, CarouselRightArrow } from './styles';

import 'react-alice-carousel/lib/alice-carousel.css';

const Carousel: FunctionComponent<ICarouselProps> = (props: ICarouselProps) => {
  const { autoPlay = false, defaultIndex = 0, items, onIndexChange, renderItem } = props;
  const [index, setIndex] = useState<number>(defaultIndex);
  const { width } = useWindowSize();
  const isMobile = width < SIZES.bigTablet;

  useEffect(() => {
    if (onIndexChange) onIndexChange(index);
  }, [index, onIndexChange]);

  const responsive = {
    [SIZES.bigDesktop]: { items: autoPlay ? 3 : 5 },
    [SIZES.smallDesktop]: { items: autoPlay ? 3 : 4 },
    [SIZES.bigTablet]: { items: autoPlay ? 3 : 4 },
    [SIZES.mediumTablet]: { items: autoPlay ? 3 : 4 },
    [SIZES.smallTablet]: { items: autoPlay ? 2 : 3 },
    [SIZES.mediumPhone]: { items: 2 },
    0: { items: 1 },
  };

  const onPreviousClick = () => setIndex(index === 0 ? items.length - 1 : index - 1);
  const onNextClick = () => setIndex(index === items.length - 1 ? 0 : index + 1);

  return (
    <CarouselContainer autoPlay={autoPlay} data-testid="carousel">
      <AliceCarousel
        activeIndex={index}
        animationDuration={autoPlay ? 500 : 0}
        animationType="fadeout"
        autoPlay={autoPlay}
        autoPlayInterval={3000}
        disableDotsControls
        infinite
        items={items.map((item, i) => renderItem(item, i === index, () => setIndex(i)))}
        onSlideChanged={({ item }) => setIndex(item)}
        paddingRight={autoPlay && !isMobile ? 120 : 0}
        responsive={responsive}
        renderPrevButton={({ isDisabled }) =>
          !autoPlay && (
            <CarouselLeftArrow
              data-testid="carousel-left-arrow-button"
              disabled={isDisabled}
              onClick={onPreviousClick}
            >
              <LeftArrow />
            </CarouselLeftArrow>
          )
        }
        renderNextButton={({ isDisabled }) =>
          !autoPlay && (
            <CarouselRightArrow
              data-testid="carousel-right-arrow-button"
              disabled={isDisabled}
              onClick={onNextClick}
            >
              <RightArrow />
            </CarouselRightArrow>
          )
        }
      />
    </CarouselContainer>
  );
};

export default Carousel;
