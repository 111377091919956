import React, { FunctionComponent } from 'react';
import { Redirect, Switch, SwitchProps } from 'react-router-dom';

import Route from 'containers/RouteContainer';
import HomeContainer from 'containers/HomeContainer';
import UsContainer from 'containers/UsContainer';
import ServicesContainer from 'containers/ServicesContainer';
import BlogsContainer from 'containers/BlogsContainer';
import BlogContainer from 'containers/BlogContainer';
import FAQContainer from 'containers/FAQContainer';
import ContactContainer from 'containers/ContactContainer';
import ErrorView from 'views/ErrorView';

const NavigationContainer: FunctionComponent<SwitchProps> = () => (
  <Switch>
    <Redirect exact from="/" to="/home" />
    {/* Public Routes */}
    <Route exact path="/home" component={HomeContainer} />
    <Route exact path="/us" component={UsContainer} />
    <Route exact path="/services" component={ServicesContainer} />
    <Route exact path="/blog" component={BlogsContainer} />
    <Route exact path="/blog/:id" component={BlogContainer} />
    <Route exact path="/faq" component={FAQContainer} />
    <Route exact path="/contact" component={ContactContainer} />
    {/* Default Route */}
    <Route component={ErrorView} />
  </Switch>
);

export default NavigationContainer;
