import React, { Fragment, FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import i18next from 'i18next';

import { EMAIL_ADDRESS, WHATSAPP_URL } from 'configs/configs';
import {
  composeValidators,
  emailValidation,
  phoneNumberValidation,
  requiredValidation,
} from 'helpers/validations';

import Input from 'components/inputs/Input';
import Captcha from 'components/inputs/Captcha';

import { ReactComponent as Email } from 'views/ContactView/assets/email.svg';
import { ReactComponent as Whatsapp } from 'views/ContactView/assets/whatsapp.svg';

import { FormContent, FormRowContainer, FormSubmitButton } from 'styles/form';

import { IContactViewProps } from './types';
import {
  ContactViewContainer,
  ContactViewContent,
  ContactViewPropertyContainer,
  ContactViewPropertyTitle,
  ContactViewPropertySubtitle,
  ContactViewPropertyInfoContainer,
  ContactViewPropertyInfoText,
  ContactViewFormContainer,
  ContactViewFormTitle,
  ContactViewFormButtonContainer,
} from './styles';
import './i18n';

const ContactView: FunctionComponent<IContactViewProps> = (props: IContactViewProps) => {
  const { formRef, isFAQ, isView, onSubmit } = props;

  const InputsContainer = isView ? Fragment : FormRowContainer;

  const renderEmailField = () => (
    <Field
      label={i18next.t<string>('CONTACT_VIEW:FORM_EMAIL')}
      name="email"
      render={Input}
      type="email"
      validate={composeValidators(requiredValidation, emailValidation)}
    />
  );

  const renderPhoneField = () => (
    <Field
      label={i18next.t<string>('CONTACT_VIEW:FORM_PHONE')}
      maxLength={20}
      name="phone"
      render={Input}
      validate={composeValidators(requiredValidation, phoneNumberValidation)}
    />
  );

  return (
    <ContactViewContainer data-testid="contact-view" isView={isView}>
      <ContactViewContent isView={isView}>
        <ContactViewPropertyContainer>
          <ContactViewPropertyTitle>
            {i18next.t<string>(`CONTACT_VIEW:${isFAQ ? 'FAQ_TITLE' : 'PROPERTY_TITLE'}`)}
          </ContactViewPropertyTitle>
          <ContactViewPropertySubtitle>
            {i18next.t<string>('CONTACT_VIEW:PROPERTY_SUBTITLE')}
          </ContactViewPropertySubtitle>
          <ContactViewPropertyInfoContainer>
            <ContactViewPropertyInfoText
              href={`mailto:${EMAIL_ADDRESS}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Email /> {EMAIL_ADDRESS}
            </ContactViewPropertyInfoText>
            <ContactViewPropertyInfoText
              href={WHATSAPP_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Whatsapp /> +54 9 11 5607-1366
            </ContactViewPropertyInfoText>
          </ContactViewPropertyInfoContainer>
        </ContactViewPropertyContainer>
        <ContactViewFormContainer isView={isView}>
          {isView && (
            <ContactViewFormTitle>
              {i18next.t<string>('CONTACT_VIEW:FORM_TITLE')}
            </ContactViewFormTitle>
          )}
          <Form
            onSubmit={onSubmit}
            render={({ form, handleSubmit, submitting }) => {
              formRef.current = form;
              return (
                <FormContent data-testid="contact-form" onSubmit={handleSubmit}>
                  <InputsContainer>
                    <Field
                      label={i18next.t<string>('CONTACT_VIEW:FORM_NAME')}
                      name="name"
                      render={Input}
                      validate={requiredValidation}
                    />
                    {isView ? renderEmailField() : renderPhoneField()}
                  </InputsContainer>
                  {isView ? renderPhoneField() : renderEmailField()}
                  <Field
                    label={i18next.t<string>('CONTACT_VIEW:FORM_COMMENTS')}
                    maxLength={2000}
                    name="comments"
                    render={Input}
                    type="textarea"
                    validate={requiredValidation}
                  />
                  <Field name="captcha" render={Captcha} validate={requiredValidation} />
                  <ContactViewFormButtonContainer isView={isView}>
                    <FormSubmitButton disabled={submitting} type="submit" variant="outlined">
                      {i18next.t<string>('CONTACT_VIEW:FORM_SUBMIT_BUTTON')}
                      {!isView && i18next.t<string>('CONTACT_VIEW:FORM')}
                    </FormSubmitButton>
                  </ContactViewFormButtonContainer>
                </FormContent>
              );
            }}
          />
        </ContactViewFormContainer>
      </ContactViewContent>
    </ContactViewContainer>
  );
};

export default ContactView;
