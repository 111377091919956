import { createSelector } from 'reselect';

import { ASSETS_URL } from 'configs/configs';

import { IAppState } from 'types/app.types';

const getLastBlogs = (state: { app: IAppState }) => state.app.blogs;
const getIsMenuOpened = (state: { app: IAppState }) => state.app.isMenuOpened;

export const isMenuOpenedSelector = createSelector(
  [getIsMenuOpened],
  (isMenuOpened) => isMenuOpened,
);

export const lastBlogsSelector = createSelector([getLastBlogs], (blogs) =>
  blogs.map((blog) => ({
    ...blog,
    image: ASSETS_URL + blog.image,
  })),
);
