import React, { FunctionComponent } from 'react';

import { getFormattedDate } from 'helpers/dates';

import { ReactComponent as Avatar } from './assets/avatar.svg';
import { IAuthorProps } from './types';
import { AuthorContainer, AuthorInfoContainer, AuthorInfoName, AuthorInfoDate } from './styles';

const Author: FunctionComponent<IAuthorProps> = (props: IAuthorProps) => {
  const { date, name } = props;

  return (
    <AuthorContainer>
      <Avatar />
      <AuthorInfoContainer>
        <AuthorInfoName>{name}</AuthorInfoName>
        <AuthorInfoDate>{getFormattedDate(date)}</AuthorInfoDate>
      </AuthorInfoContainer>
    </AuthorContainer>
  );
};

export default Author;
