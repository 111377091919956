import { combineReducers } from 'redux';

import appReducer from 'redux/reducers/app.reducer';
import blogsReducer from 'redux/reducers/blogs.reducer';
import servicesReducer from 'redux/reducers/services.reducer';

const rootReducer = combineReducers({
  app: appReducer,
  blogs: blogsReducer,
  services: servicesReducer,
});

export default rootReducer;
