import cookie from 'react-cookies';

interface IToken {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

const baseOptions = { path: '/' };

export const getCookie = (key: string): string => {
  return cookie.load(key);
};

export const setCookie = (key: string, value: string, options = {}): void => {
  cookie.save(key, value, { ...baseOptions, ...options });
};

export const removeCookie = (key: string, options = {}): void => {
  cookie.remove(key, { ...baseOptions, ...options });
};

export const saveUserCookies = (token: IToken): void => {
  const { accessToken, expiresIn, refreshToken } = token;
  setCookie('accessToken', accessToken, { maxAge: expiresIn });
  setCookie('refreshToken', refreshToken);
};

export const removeUserCookies = (): void => {
  removeCookie('accessToken');
  removeCookie('refreshToken');
};

export const saveBlogViewedCookie = (id: string): boolean => {
  try {
    const blogs = (getCookie('blogs_viewed') || '').split(',');
    if (blogs.includes(id)) return false;
    blogs.push(id);
    setCookie('blogs_viewed', blogs.join(','), { maxAge: 86400 });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
