import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { history } from 'helpers/history';

import LayoutContainer from 'containers/LayoutContainer';
import ErrorImg from 'views/ErrorView/assets/404.png';

import { ViewContainer } from 'styles/view';
import { ErrorContainer, ErrorTitle, ErrorImage, ErrorButton } from './styles';

import './i18n';

const ErrorView: FunctionComponent = () => (
  <LayoutContainer title={i18next.t<string>('ERROR_VIEW:TITLE')}>
    <ViewContainer data-testid="error-view">
      <ErrorContainer>
        <ErrorImage src={ErrorImg} alt="404" />
        <ErrorTitle>{i18next.t<string>('ERROR_VIEW:TITLE')}</ErrorTitle>
        <ErrorButton onClick={() => history.push('/')} variant="outlined">
          {i18next.t<string>('ERROR_VIEW:BUTTON')}
        </ErrorButton>
      </ErrorContainer>
    </ViewContainer>
  </LayoutContainer>
);

export default ErrorView;
