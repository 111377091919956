import React, { FunctionComponent, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { RECAPTCHA_SITE_KEY } from 'configs/configs';

import { ICaptchaProps } from './types';
import { CustomFormGroup, CustomErrorBlock } from './styles';

const Captcha: FunctionComponent<ICaptchaProps> = (props: ICaptchaProps) => {
  const {
    className = '',
    input: { onChange, value },
    meta: { touched, error },
  } = props;
  const inputRef = useRef<ReCAPTCHA>(null);
  const hasError = !!touched && !!error;

  useEffect(() => {
    if (!value && inputRef.current) inputRef.current.reset();
  }, [value]);

  return (
    <CustomFormGroup className={className}>
      <ReCAPTCHA
        data-testid="captcha"
        onChange={onChange}
        ref={inputRef}
        sitekey={RECAPTCHA_SITE_KEY}
      />
      {hasError && <CustomErrorBlock data-testid="error-block">{error}</CustomErrorBlock>}
    </CustomFormGroup>
  );
};

export default Captcha;
