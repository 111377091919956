import React, { FunctionComponent } from 'react';

import { WHATSAPP_URL } from 'configs/configs';

import WhatsappImg from 'components/main/Whatsapp/assets/whatsapp.png';

import { WhatsappContainer, WhatsappImage } from './styles';

const Whatsapp: FunctionComponent = () => (
  <WhatsappContainer
    data-testid="whatsapp"
    href={WHATSAPP_URL}
    rel="noopener noreferrer"
    target="_blank"
  >
    <WhatsappImage alt="whatsapp" src={WhatsappImg} />
  </WhatsappContainer>
);

export default Whatsapp;
