export const APP_ON_CONTACT_REQUESTED = 'APP_ON_CONTACT_REQUESTED';
export const APP_ON_CONTACT_SUCCEEDED = 'APP_ON_CONTACT_SUCCEEDED';
export const APP_ON_CONTACT_FAILED = 'APP_ON_CONTACT_FAILED';
export const APP_ON_CONTACT_RESET = 'APP_ON_CONTACT_RESET';

export const APP_ON_GET_LAST_BLOGS_REQUESTED = 'APP_ON_GET_LAST_BLOGS_REQUESTED';
export const APP_ON_GET_LAST_BLOGS_SUCCEEDED = 'APP_ON_GET_LAST_BLOGS_SUCCEEDED';
export const APP_ON_GET_LAST_BLOGS_FAILED = 'APP_ON_GET_LAST_BLOGS_FAILED';

export const APP_ON_INITIALIZE_REQUESTED = 'APP_ON_INITIALIZE_REQUESTED';

export const APP_ON_TOGGLE_MENU_REQUESTED = 'APP_ON_TOGGLE_MENU_REQUESTED';
