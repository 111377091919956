import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import useDocumentTitle from 'hooks/useDocumentTitle';
import useWindowSize from 'hooks/useWindowSize';
import { NAVBAR_HEIGHT, FOOTER_HEIGHT } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import HeaderContainer from 'containers/HeaderContainer';
import Footer from 'components/main/Footer';
import Whatsapp from 'components/main/Whatsapp';

interface ILayoutContainerProps {
  children: ReactNode;
  title: string;
}

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
`;

const ViewContainer = styled.div`
  width: 100%;
  margin-top: ${NAVBAR_HEIGHT}px;
  min-height: calc(100vh - ${NAVBAR_HEIGHT + FOOTER_HEIGHT}px);

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 0;
  }
`;

const LayoutContainer: FunctionComponent<ILayoutContainerProps> = (
  props: ILayoutContainerProps,
) => {
  const { children, title } = props;
  useDocumentTitle(`${title} - Administro tu propiedad`);
  const { width } = useWindowSize();
  const isMobile = width < SIZES.bigTablet;

  return (
    <GlobalContainer data-testid="global-container">
      <HeaderContainer isMobile={isMobile} />
      <ViewContainer>{children}</ViewContainer>
      <Footer />
      <Whatsapp />
    </GlobalContainer>
  );
};

export default LayoutContainer;
