import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/app.constants';
import { contact, getLastBlogs } from 'services/app.services';

export function* appContact(action: AnyAction): Generator {
  try {
    const { formData } = action;
    const { data }: any = yield call(contact, formData);
    yield put({ type: constants.APP_ON_CONTACT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_ON_CONTACT_FAILED, error });
  }
}

export function* appGetLastBlogs(): Generator {
  try {
    const { data }: any = yield call(getLastBlogs);
    yield put({ type: constants.APP_ON_GET_LAST_BLOGS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_ON_GET_LAST_BLOGS_FAILED, error });
  }
}

export function* watchApp(): Generator {
  yield all([
    takeLatest(constants.APP_ON_CONTACT_REQUESTED, appContact),
    takeLatest(constants.APP_ON_GET_LAST_BLOGS_REQUESTED, appGetLastBlogs),
  ]);
}
