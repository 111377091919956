import { Reducer } from 'redux';

import { IServicesState } from 'types/services.types';
import * as constants from 'redux/constants/services.constants';

export const defaultState: IServicesState = {
  items: [],
  loading: false,
};

const servicesReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.SERVICES_ON_GET_ALL_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.SERVICES_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        items: data.items,
        loading: false,
      };
    case constants.SERVICES_ON_GET_ALL_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default servicesReducer;
