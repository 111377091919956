import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetAll } from 'redux/actions/blogs.actions';
import { blogsSelector } from 'redux/selectors/blogs.selector';

import LayoutContainer from 'containers/LayoutContainer';
import BlogsView from 'views/BlogsView';

import 'views/BlogsView/i18n';

const BlogsContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { loading } = useTypedSelector((state) => state.blogs);
  const items = useTypedSelector(blogsSelector);

  useEffect(() => {
    dispatch(onGetAll());
  }, [dispatch]);

  return (
    <LayoutContainer title={i18next.t<string>('BLOGS_VIEW:TITLE')}>
      <BlogsView items={items} loading={loading} />
    </LayoutContainer>
  );
};

export default BlogsContainer;
