import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';

const RouteContainer: FunctionComponent<RouteProps> = ({ component: Component, ...rest }) => {
  if (!Component) return null;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default RouteContainer;
