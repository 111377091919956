export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL as string;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL as string;
export const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL as string;

export const EMAIL_ADDRESS = process.env.REACT_APP_EMAIL_ADDRESS as string;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
