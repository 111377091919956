import { AnyAction } from 'redux';

import * as constants from 'redux/constants/app.constants';
import { IContactFormData } from 'views/ContactView/types';

export const onContact = (formData: IContactFormData): AnyAction => ({
  type: constants.APP_ON_CONTACT_REQUESTED,
  formData,
});

export const onContactReset = (): AnyAction => ({
  type: constants.APP_ON_CONTACT_RESET,
});

export const onGetLastBlogs = (): AnyAction => ({
  type: constants.APP_ON_GET_LAST_BLOGS_REQUESTED,
});

export const onInitialize = (): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_REQUESTED,
});

export const onToggleMenu = (isMenuOpened: boolean): AnyAction => ({
  type: constants.APP_ON_TOGGLE_MENU_REQUESTED,
  isMenuOpened,
});
