import styled, { css } from 'styled-components';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as FAQ } from 'views/FAQView/assets/faq.svg';

import { ViewContent } from 'styles/view';

export const FAQViewContent = styled(ViewContent)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 100px 0;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FAQViewImage = styled(FAQ)`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 300px;
    height: 400px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    width: 250px;
    height: 300px;
  }
`;

export const FAQViewImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FAQViewQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 680px;
  margin-left: 100px;
  padding-top: 41px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    margin-left: 50px;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 80%;
    margin-left: 0;
  }
`;

export const FAQViewQuestionContainer = styled(Accordion)`
  && {
    width: 100%;
    margin-top: 0 !important;
    box-shadow: none;

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }

    ::before {
      height: 0.5px;
      border-color: ${COLORS.grayManatee};
      opacity: 1 !important;
    }
  }
`;

export const FAQViewQuestionContent = styled(AccordionSummary)`
  && {
    width: 100%;
    height: 80px;
    padding: 0;
    color: ${COLORS.blueLuckyPoint};
    font-family: 'Inter', Helvetica, Arial, serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;

    ${({ expanded }: { expanded?: boolean }) =>
      expanded &&
      css`
        color: ${COLORS.pinkCarnation};
      `}
  }
`;

export const FAQViewQuestionDescription = styled(AccordionDetails)`
  && {
    padding: 0;
    color: ${COLORS.blueLuckyPoint};
    font-family: 'Inter', Helvetica, Arial, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    a {
      color: ${COLORS.blueLuckyPoint};
      text-decoration: underline;

      :hover {
        opacity: 0.5;
      }
    }
  }
`;
