import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { IAppState } from 'types/app.types';
import { IBlogsState } from 'types/blogs.types';
import { IServicesState } from 'types/services.types';

export interface IRootState {
  app: IAppState;
  blogs: IBlogsState;
  services: IServicesState;
}

const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;

export default useTypedSelector;
