import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ViewContent } from 'styles/view';

import { ReactComponent as ArrowBack } from './assets/arrow-back.svg';

export const BlogViewContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const BlogViewArrowBack = styled(ArrowBack)`
  && {
    cursor: pointer;

    :hover {
      opacity: 0.5;
    }
  }
`;

export const BlogViewLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`;

export const BlogViewContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 80px 0;
`;

export const BlogViewTitle = styled.span`
  margin-top: 30px;
  color: ${COLORS.blueLuckyPoint};
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
`;

export const BlogViewText = css`
  color: ${COLORS.blueLuckyPoint};
  font-size: 16px;
  font-family: 'Inter', Helvetica, Arial, serif;
  font-weight: 400;
  line-height: 22px;
`;

export const BlogViewIntroduction = styled.span`
  margin-top: 10px;
  ${BlogViewText}
`;

export const BlogViewImage = styled.img`
  width: 100%;
  margin-top: 20px;
`;

export const BlogViewDescription = styled.span`
  margin-top: 20px;
  ${BlogViewText}

  ol {
    padding-left: 20px;

    li {
      margin-bottom: 20px;
    }
  }
`;

export const BlogViewExploreMoreContentContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  margin: 100px 0;
`;

export const BlogViewExploreMoreContentTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
`;

export const BlogViewExploreMoreContentBlogContainer = styled.div`
  position: relative;
  margin-top: 50px;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 120px);
    pointer-events: none;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    :after {
      content: none;
    }
  }
`;
