import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Close } from '@mui/icons-material';

import { SIDEBAR_WIDTH } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';

export const SidebarContainer = styled.div<{ open: boolean }>`
  position: fixed;
  width: ${({ open }) => (open ? `${SIDEBAR_WIDTH}px` : '0')};
  min-width: 0;
  height: 100vh;
  background-color: ${COLORS.white};
  box-shadow: ${COLORS.boxShadow};
  overflow: hidden;
  transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 10;
`;

export const SidebarContent = styled.ul`
  height: 100%;
  margin: 15px;
  padding: 0 0 20px 0;
  overflow: hidden;
`;

export const SidebarToggleMenu = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px 10px;
`;

export const SidebarCloseButton = styled(Close)`
  && {
    color: ${COLORS.gray};
    cursor: pointer;

    &:hover {
      color: ${COLORS.blueLuckyPoint};
    }
  }
`;

export const SidebarItem = styled(NavLink)<{ open: boolean }>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    color: ${COLORS.blueLuckyPoint};
    font-family: 'Inter', Helvetica, Arial, serif;
    border-radius: 4px;

    &.active {
      background-color: ${COLORS.pinkCarnation};
      color: ${COLORS.white};
    }

    span {
      margin-left: 10px;
      font-size: 16px;
    }

    ${({ open }) =>
      open &&
      css`
        &:hover {
          padding-left: 25px;
          transition: padding 0.35s ease 0s !important;
        }

        &:not(:hover) {
          padding-right: 25px;
          transition: padding 0.35s ease 0s !important;
        }
      `}
  }
`;

export const SidebarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: ${COLORS.modalBackground};
  z-index: 8;
`;
