import { AxiosResponse } from 'axios';

import { get, post } from 'services/api';

import { IContactFormData } from 'views/ContactView/types';

export const contact = (formData: IContactFormData): Promise<AxiosResponse> =>
  post('contact', formData);

export const getLastBlogs = (): Promise<AxiosResponse> => get('getAllBlogs?limit=10');
