import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { EMAIL_ADDRESS, WHATSAPP_URL } from 'configs/configs';

import Logo from 'components/commons/Logo';

import {
  FooterContainer,
  FooterContent,
  FooterLogoContainer,
  FooterCopyright,
  FooterLinksContainer,
  FooterLink,
  FooterInfoContainer,
  FooterInfoSectionContainer,
  FooterInfoSectionTitle,
  FooterInfoSectionText,
} from './styles';

import './i18n';

const Footer: FunctionComponent = () => (
  <FooterContainer data-testid="footer">
    <FooterContent>
      <FooterLogoContainer>
        <Logo variant="outlined" />
        <FooterCopyright>
          &copy; {new Date().getFullYear()} administrotupropiedad.com.ar
          <br />
          {i18next.t<string>('FOOTER:COPYRIGHT')}
        </FooterCopyright>
        <FooterLinksContainer>
          <FooterLink to="/blog">{i18next.t<string>('FOOTER:BLOG')}</FooterLink>
          <FooterLink to="/faq">{i18next.t<string>('FOOTER:FAQ')}</FooterLink>
          <FooterLink to="/contact">{i18next.t<string>('FOOTER:CONTACT')}</FooterLink>
        </FooterLinksContainer>
      </FooterLogoContainer>
      <FooterInfoContainer>
        <FooterInfoSectionContainer>
          <FooterInfoSectionTitle>{i18next.t<string>('FOOTER:CONTACT')}</FooterInfoSectionTitle>
          <FooterInfoSectionText
            href={WHATSAPP_URL}
            rel="noopener noreferrer"
            target="_blank"
            underlined
          >
            +54 9 11 5607-1366
          </FooterInfoSectionText>
          <FooterInfoSectionText
            href={`mailto:${EMAIL_ADDRESS}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {EMAIL_ADDRESS}
          </FooterInfoSectionText>
        </FooterInfoSectionContainer>
        <FooterInfoSectionContainer>
          <FooterInfoSectionTitle>{i18next.t<string>('FOOTER:SUPPORT')}</FooterInfoSectionTitle>
          <FooterInfoSectionText important>
            {i18next.t<string>('FOOTER:ATENTION')}
          </FooterInfoSectionText>
        </FooterInfoSectionContainer>
      </FooterInfoContainer>
    </FooterContent>
  </FooterContainer>
);

export default Footer;
