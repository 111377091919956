import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const BlogPreviewContainer = styled(Link)`
  && {
    display: flex;
    border: 1px solid ${COLORS.blueLuckyPoint};
    border-radius: 20px;

    :hover {
      opacity: 0.5;
    }

    ${({ isLarge, isReversed }: { isLarge: boolean; isReversed: boolean }) =>
      isLarge
        ? css`
            flex-direction: ${isReversed ? 'row' : 'row-reverse'};
            margin: 0 70px 30px;

            @media (max-width: ${SIZES.bigTablet}px) {
              margin: 0 0 30px;
            }

            @media (max-width: ${SIZES.bigPhone}px) {
              flex-direction: column;
              width: 80%;
            }
          `
        : css`
            flex-direction: column;
            margin-left: 2px;
            margin-right: 28px;

            @media (max-width: ${SIZES.bigTablet}px) {
              margin-right: 2px;
            }
          `}
  }
`;

export const BlogPreviewImage = styled.img`
  border-top-left-radius: 20px;

  ${({ isLarge, isReversed }: { isLarge: boolean; isReversed: boolean }) =>
    isLarge
      ? css`
          width: 50%;
          ${isReversed
            ? css`
                border-bottom-left-radius: 20px;
              `
            : css`
                border-top-left-radius: 0;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
              `}

          @media (max-width: ${SIZES.bigPhone}px) {
            width: auto;
            height: 200px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        `
      : css`
          height: 200px;
          border-top-right-radius: 20px;
        `}
`;

export const BlogPreviewInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    padding: 20px;
  }
`;

export const BlogPreviewTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-family: 'Inter', Helvetica, Arial, serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;

  ${({ isLarge }: { isLarge: boolean }) =>
    isLarge
      ? css`
          height: 80px;
          margin-top: 16px;
          font-size: 28px;
          font-weight: 700;

          @media (max-width: ${SIZES.mediumTablet}px) {
            height: 50px;
            font-size: 16px;
          }
        `
      : css`
          height: 40px;
          margin-top: 25px;
          font-size: 16px;
          font-weight: 600;
        `}
`;

export const BlogPreviewIntroduction = styled.span`
  height: 45px;
  margin-top: 10px;
  color: ${COLORS.blueLuckyPoint};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
`;

export const BlogPreviewVisits = styled.span`
  padding: 5px 5px 0 0;
  margin-top: 20px;
  color: ${COLORS.grayManatee};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  border-top: 0.5px solid ${COLORS.grayManatee};
`;
