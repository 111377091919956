export const isObject = (value: Record<string, unknown>): boolean => {
  return value === Object(value) && !Array.isArray(value) && typeof value !== 'function';
};

export const camelCase = (value: string): string =>
  value.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });

export const snakeCase = (value: string): string =>
  value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const parseObjectKeys = (
  value: Record<string, unknown>,
  callback: (value: string) => string,
): unknown => {
  if (isObject(value)) {
    const object: Record<string, unknown> = {};
    Object.keys(value).forEach((key) => {
      object[callback(key)] = parseObjectKeys(value[key] as Record<string, unknown>, callback);
    });
    return object;
  }
  if (Array.isArray(value)) return value.map((item) => parseObjectKeys(item, callback));
  return value;
};

export const camelCaseKeys = (value: Record<string, unknown>): unknown =>
  parseObjectKeys(value, camelCase);

export const snakeCaseKeys = (value: Record<string, unknown>): unknown =>
  parseObjectKeys(value, snakeCase);
