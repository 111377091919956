import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AuthorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  color: ${COLORS.black};
  font-family: 'Inter', Helvetica, Arial, serif;
`;

export const AuthorInfoName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`;

export const AuthorInfoDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-transform: capitalize;
`;
