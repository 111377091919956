import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'INPUT', {
  CURRENT_IMAGE: 'Current image',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'INPUT', {
  CURRENT_IMAGE: 'Imagen actual',
});
