import React, { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

import Carousel from 'components/commons/Carousel';
import Loading from 'components/commons/Loading';

import { ViewContainer } from 'styles/view';

import { IService } from 'types/services.types';
import { IServicesViewProps } from './types';
import {
  WhatWeOfferContainer,
  WhatWeOfferTitle,
  WhatWeOfferLoadingContainer,
  WhatWeOfferServiceContainer,
  WhatWeOfferServiceIcon,
  WhatWeOfferServiceName,
  WhatWeOfferSelectedServiceContainer,
  WhatWeOfferSelectedServiceInfoContainer,
  WhatWeOfferSelectedServiceTitleContainer,
  WhatWeOfferSelectedServiceIcon,
  WhatWeOfferSelectedServiceDescription,
  WhatWeOfferSelectedServiceImage,
} from './styles';
import './i18n';

const ServicesView: FunctionComponent<IServicesViewProps> = (props: IServicesViewProps) => {
  const { loading, items } = props;
  const [selectedService, setSelectedService] = useState<IService | null>(null);
  const { state } = useLocation<{ key: number }>();
  const defaultIndex = items.findIndex(({ id }) => id === state?.key);

  const onIndexChange = (index: number) => setSelectedService(items[index]);

  return (
    <ViewContainer data-testid="services-view">
      <WhatWeOfferContainer>
        <WhatWeOfferTitle>
          {i18next.t<string>('SERVICES_VIEW:WHAT_WE_OFFER_TITLE')}
        </WhatWeOfferTitle>
        {loading ? (
          <WhatWeOfferLoadingContainer>
            <Loading />
          </WhatWeOfferLoadingContainer>
        ) : (
          <>
            <Carousel
              defaultIndex={defaultIndex !== -1 ? defaultIndex : 0}
              items={items}
              onIndexChange={onIndexChange}
              renderItem={(item: IService, isActiveIndex: boolean, setActiveIndex: () => void) => (
                <WhatWeOfferServiceContainer
                  active={isActiveIndex}
                  key={`service-${item.name}`}
                  onClick={setActiveIndex}
                >
                  <WhatWeOfferServiceIcon active={isActiveIndex} alt={item.name} src={item.icon} />
                  <WhatWeOfferServiceName active={isActiveIndex}>
                    {item.name}
                  </WhatWeOfferServiceName>
                </WhatWeOfferServiceContainer>
              )}
            />
            {selectedService && (
              <WhatWeOfferSelectedServiceContainer>
                <WhatWeOfferSelectedServiceInfoContainer>
                  <WhatWeOfferSelectedServiceTitleContainer>
                    <WhatWeOfferSelectedServiceIcon
                      alt={selectedService.name}
                      src={selectedService.icon}
                    />
                    {selectedService.name}
                  </WhatWeOfferSelectedServiceTitleContainer>
                  <WhatWeOfferSelectedServiceDescription>
                    {selectedService.description}
                  </WhatWeOfferSelectedServiceDescription>
                </WhatWeOfferSelectedServiceInfoContainer>
                <WhatWeOfferSelectedServiceImage
                  alt={selectedService.name}
                  src={selectedService.image}
                />
              </WhatWeOfferSelectedServiceContainer>
            )}
          </>
        )}
      </WhatWeOfferContainer>
    </ViewContainer>
  );
};

export default ServicesView;
