import React, { FunctionComponent } from 'react';

import { IButtonProps } from './types';
import { CustomButton } from './styles';

const Button: FunctionComponent<IButtonProps> = (props: IButtonProps) => {
  const {
    children,
    className = '',
    color = 'primary',
    disabled = false,
    onClick = () => {},
    type = 'button',
    variant = 'contained',
  } = props;

  return (
    <CustomButton
      className={`${color} ${variant} ${className}`}
      data-testid="button"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </CustomButton>
  );
};

export default Button;
