import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetLastBlogs } from 'redux/actions/app.actions';
import { lastBlogsSelector } from 'redux/selectors/app.selector';

import LayoutContainer from 'containers/LayoutContainer';
import ContactContainer from 'containers/ContactContainer';
import HomeView from 'views/HomeView';

import Photography from 'views/HomeView/assets/photography.png';
import Publish from 'views/HomeView/assets/publish.png';
import Atention from 'views/HomeView/assets/atention.png';
import Maintenance from 'views/HomeView/assets/maintenance.png';
import Optimization from 'views/HomeView/assets/optimization.png';

import 'views/HomeView/i18n';

const HomeContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const blogs = useTypedSelector(lastBlogsSelector);

  useEffect(() => {
    dispatch(onGetLastBlogs());
  }, [dispatch]);

  const offers = [
    {
      description: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_PHOTOGRAPHY_DESCRIPTION'),
      image: Photography,
      key: 2,
      title: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_PHOTOGRAPHY_TITLE'),
    },
    {
      description: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_PUBLISH_DESCRIPTION'),
      image: Publish,
      key: 3,
      title: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_PUBLISH_TITLE'),
    },
    {
      description: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_ATENTION_DESCRIPTION'),
      image: Atention,
      key: 6,
      title: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_ATENTION_TITLE'),
    },
    {
      description: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_MAINTENANCE_DESCRIPTION'),
      image: Maintenance,
      key: 8,
      title: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_MAINTENANCE_TITLE'),
    },
    {
      description: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_OPTIMIZATION_DESCRIPTION'),
      image: Optimization,
      key: 3,
      title: i18next.t<string>('HOME_VIEW:WHAT_WE_OFFER_OPTIMIZATION_TITLE'),
    },
  ];

  return (
    <LayoutContainer title={i18next.t<string>('HOME_VIEW:TITLE')}>
      <HomeView blogs={blogs} offers={offers} />
      <ContactContainer isView />
    </LayoutContainer>
  );
};

export default HomeContainer;
