import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';

import { FormGroup, ErrorBlock } from 'styles/input';

export const CustomFormGroup = styled(FormGroup)`
  @media (max-width: ${SIZES.bigPhone}px) {
    transform: scale(0.8);
    transform-origin: 0 0;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    transform: scale(0.7);
  }

  @media (max-width: ${SIZES.smallMediumPhone}px) {
    transform: scale(0.6);
  }

  @media (max-width: ${SIZES.smallPhone}px) {
    transform: scale(0.5);
  }
`;

export const CustomErrorBlock = styled(ErrorBlock)`
  @media (max-width: ${SIZES.bigPhone}px) {
    font-size: 15px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 17px;
  }

  @media (max-width: ${SIZES.smallMediumPhone}px) {
    font-size: 20px;
  }

  @media (max-width: ${SIZES.smallPhone}px) {
    font-size: 24px;
  }
`;
