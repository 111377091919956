import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { IButtonProps } from './types';

export const CustomButton = styled.button`
  padding: 8px 16px;
  color: ${COLORS.white};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  outline: none;

  ${({ disabled }: IButtonProps) =>
    disabled
      ? css`
          border: 2px solid ${COLORS.grayAlto};
          cursor: not-allowed;

          &.primary.contained,
          &.error.contained {
            background: ${COLORS.grayAlto};
          }

          &.primary.outlined,
          &.error.outlined {
            color: ${COLORS.grayAlto};
            background: ${COLORS.grayAlabaster};
          }
        `
      : css`
          &.primary.contained {
            background: ${COLORS.blueLuckyPoint};
            border: 2px solid ${COLORS.blueLuckyPoint};

            :hover {
              background: ${COLORS.blueZodiac};
              border: 2px solid ${COLORS.blueZodiac};
            }
          }

          &.primary.outlined {
            color: ${COLORS.blueLuckyPoint};
            background: ${COLORS.white};
            border: 2px solid ${COLORS.blueLuckyPoint};

            :hover {
              background: ${COLORS.grayAlto};
            }
          }

          &.error.contained {
            background: ${COLORS.redMonza};
            border: 2px solid ${COLORS.redMonza};

            :hover {
              background: ${COLORS.redMilano};
              border: 2px solid ${COLORS.redMilano};
            }
          }

          &.error.outlined {
            color: ${COLORS.redMonza};
            background: ${COLORS.white};
            border: 2px solid ${COLORS.redMonza};

            :hover {
              background: ${COLORS.grayAlto};
            }
          }
        `}
`;
