import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ViewContent } from 'styles/view';

export const WhatWeOfferContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
`;

export const WhatWeOfferTitle = styled.span`
  margin-bottom: 50px;
  color: ${COLORS.pinkCarnation};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
`;

export const WhatWeOfferLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`;

export const WhatWeOfferServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 140px;
  border-bottom: 2px solid ${COLORS.grayManatee};
  cursor: pointer;

  ${({ active }: { active: boolean }) =>
    active &&
    css`
      border-bottom: 2px solid ${COLORS.blueRibbon};
    `}

  :hover {
    opacity: 0.5;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    height: 120px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    border-bottom: 2px solid ${COLORS.grayManatee};
  }
`;

export const WhatWeOfferServiceIcon = styled.img`
  width: 80px;
  height: 80px;

  ${({ active }: { active: boolean }) =>
    active &&
    css`
      filter: invert(16%) sepia(88%) saturate(4133%) hue-rotate(234deg) brightness(93%)
        contrast(108%);
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    filter: none;
  }
`;

export const WhatWeOfferServiceName = styled.span`
  width: 205px;
  margin-top: 10px;
  color: ${({ active }: { active: boolean }) =>
    active ? COLORS.blueRibbon : COLORS.blueLuckyPoint};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 150px;
    font-size: 12px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    color: ${COLORS.blueLuckyPoint};
  }
`;

export const WhatWeOfferSelectedServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(90% + 30px);
  margin-top: 50px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    margin-top: 0;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    width: 90%;
  }
`;

export const WhatWeOfferSelectedServiceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 124px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    padding-right: 100px;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    padding-right: 50px;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    padding-right: 0;
  }
`;

export const WhatWeOfferSelectedServiceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${COLORS.blueLuckyPoint};
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;

  @media (max-width: ${SIZES.desktop}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 16px;
  }
`;

export const WhatWeOfferSelectedServiceIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 10px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    width: 20px;
    height: 20px;
  }
`;

export const WhatWeOfferSelectedServiceDescription = styled.span`
  margin-top: 10px;
  color: ${COLORS.blueLuckyPoint};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 16px;
  line-height: 19px;

  @media (max-width: ${SIZES.desktop}px) {
    font-size: 14px;
  }
`;

export const WhatWeOfferSelectedServiceImage = styled.img`
  width: 576px;
  height: 256px;
  height: auto;
  border-radius: 20px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    width: 400px;
    height: 180px;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 320px;
    height: 144px;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    margin-top: 20px;
  }

  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
    height: auto;
  }
`;
