import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import Button from 'components/commons/Button';

import { ViewContent } from 'styles/view';

import { ReactComponent as Arrow } from 'views/HomeView/assets/arrow.svg';

export const TemporaryRentalContainer = styled(ViewContent)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column-reverse;
  }
`;

export const TemporaryRentalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TemporaryRentalTitle = styled.span`
  margin-top: 50px;
  color: ${COLORS.pinkCarnation};
  font-size: 90px;
  font-weight: 700;
  line-height: 85px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    font-size: 50px;
    line-height: normal;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-top: 30px;
    text-align: center;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 40px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    font-size: 30px;
  }
`;

export const TemporaryRentalSubtitle = styled.span`
  margin-top: 20px;
  color: ${COLORS.blueLuckyPoint};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;

  @media (max-width: ${SIZES.bigTablet}px) {
    text-align: center;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 20px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    margin-top: 10px;
    font-size: 16px;
  }
`;

export const TemporaryRentalButton = styled(Button)`
  width: fit-content;
  margin-top: 60px;
  padding: 20px 43px;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  border: 2px solid ${COLORS.blueLuckyPoint};
  border-radius: 10px;

  @media (max-width: ${SIZES.bigTablet}px) {
    align-self: center;
    margin-top: 30px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    padding: 10px 22px;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    margin-top: 10px;
  }
`;

export const TemporaryRentalPeople = styled.img`
  height: 555px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    height: 400px;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 80%;
    height: auto;
  }
`;

export const WhatWeOfferContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`;

export const WhatWeOfferTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;

  @media (max-width: ${SIZES.bigTablet}px) {
    text-align: center;
  }
`;

export const WhatWeOfferImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    justify-content: center;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const WhatWeOfferImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 190px;
  margin-bottom: 50px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    margin-left: 20px;

    &:first-child,
    &:last-child {
      margin-left: 0;
    }
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 10px;

    &:last-child {
      margin-left: 10px;
    }
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    margin-left: 0;

    &:last-child {
      margin-left: 0;
    }
  }
`;

export const WhatWeOfferImage = styled.img`
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: ${COLORS.blueShadow};
    transition: box-shadow 0.2s ease-in-out;
  }
`;

export const WhatWeOfferImageTitle = styled.p`
  margin-top: 25px;
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
`;

export const WhatWeOfferImageDescription = styled.span`
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 16px;
  line-height: 19px;
`;

export const WhatWeOfferButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  width: fit-content;
  height: 44px;
  margin-top: 60px;
  padding: 12px 35px;
  color: ${COLORS.blueRibbon} !important;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  border: 2px solid ${COLORS.blueRibbon} !important;
  border-radius: 10px;

  @media (max-width: ${SIZES.smallDesktop}px) {
    align-self: center;
  }
`;

export const WhatWeOfferArrow = styled(Arrow)`
  && {
    margin-left: 2px;
  }
`;

export const PublishYourPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 40px);
  margin-top: 100px;
  padding: 60px 20px;
  background: ${COLORS.grayWhisper};
`;

export const PublishYourPropertyContent = styled(ViewContent)`
  display: flex;
  flex-direction: column;
`;

export const PublishYourPropertyTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
`;

export const PublishYourPropertyCompanyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const PublishYourPropertyCompany = styled.div`
  padding: 0 25px;
  border-right: 1px solid ${COLORS.grayManatee};

  &:last-child {
    border-right: none;
  }

  @media (max-width: ${SIZES.desktop}px) {
    border: none;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    padding: 0 10px;
  }
`;

export const ExploreContentContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  margin: 100px 0;
`;

export const ExploreContentTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;

  @media (max-width: ${SIZES.bigTablet}px) {
    text-align: center;
  }
`;

export const ExploreContentBlogContainer = styled.div`
  position: relative;
  margin-top: 50px;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 120px);
    pointer-events: none;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    :after {
      content: none;
    }
  }
`;
