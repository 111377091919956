import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import LayoutContainer from 'containers/LayoutContainer';
import ContactContainer from 'containers/ContactContainer';
import FAQView from 'views/FAQView';

import 'views/FAQView/i18n';

const FAQContainer: FunctionComponent = () => {
  const items = [
    {
      description: i18next.t<string>('FAQ_VIEW:QUESTION_1_DESCRIPTION'),
      key: 'question-1',
      title: i18next.t<string>('FAQ_VIEW:QUESTION_1_TITLE'),
    },
    {
      description: i18next.t<string>('FAQ_VIEW:QUESTION_2_DESCRIPTION'),
      key: 'question-2',
      title: i18next.t<string>('FAQ_VIEW:QUESTION_2_TITLE'),
    },
    {
      description: `${i18next.t<string>(
        'FAQ_VIEW:QUESTION_3_DESCRIPTION',
      )} <a href="/services">${i18next.t<string>('FAQ_VIEW:QUESTION_3_DESCRIPTION_LINK')}</a>.`,
      key: 'question-3',
      title: i18next.t<string>('FAQ_VIEW:QUESTION_3_TITLE'),
    },
    {
      description: i18next.t<string>('FAQ_VIEW:QUESTION_4_DESCRIPTION'),
      key: 'question-4',
      title: i18next.t<string>('FAQ_VIEW:QUESTION_4_TITLE'),
    },
    {
      description: i18next.t<string>('FAQ_VIEW:QUESTION_5_DESCRIPTION'),
      key: 'question-5',
      title: i18next.t<string>('FAQ_VIEW:QUESTION_5_TITLE'),
    },
  ];

  return (
    <LayoutContainer title={i18next.t<string>('FAQ_VIEW:TITLE')}>
      <FAQView items={items} />
      <ContactContainer isFAQ isView />
    </LayoutContainer>
  );
};

export default FAQContainer;
