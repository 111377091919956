import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'CONTACT_VIEW', {
  TITLE: 'Contact',
  FAQ_TITLE: 'Any doubts?',
  PROPERTY_TITLE: 'Do you have your property in Buenos Aires?',
  PROPERTY_SUBTITLE: 'Let\'s talk!',
  FORM_TITLE: 'Contact us',
  FORM_NAME: 'Name',
  FORM_EMAIL: 'Email',
  FORM_PHONE: 'Phone',
  FORM_COMMENTS: 'Comments',
  FORM_SUBMIT_BUTTON: 'Send',
  FORM: ' form',
  FORM_SENT_SUCCESSFULLY: 'Your form has been sent successfully!',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'CONTACT_VIEW', {
  TITLE: 'Contacto',
  FAQ_TITLE: '¿Te quedó alguna duda?',
  PROPERTY_TITLE: '¿Tenés tu propiedad en Buenos Aires?',
  PROPERTY_SUBTITLE: 'Hablemos!',
  FORM_TITLE: 'Contactanos',
  FORM_NAME: 'Nombre',
  FORM_EMAIL: 'Mail',
  FORM_PHONE: 'Teléfono',
  FORM_COMMENTS: 'Comentarios',
  FORM_SUBMIT_BUTTON: 'Enviar',
  FORM: ' formulario',
  FORM_SENT_SUCCESSFULLY: '¡Tu formulario ha sido enviado con éxito!',
  LOADING: 'Cargando...',
});
