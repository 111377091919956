import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu } from '@mui/icons-material';

import { NAVBAR_HEIGHT } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const NavbarContainer = styled.nav`
  position: fixed;
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;
  background-color: ${COLORS.white};
  box-shadow: ${COLORS.boxShadow};
  overflow: hidden;
  z-index: 6;
`;

export const NavbarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 20px;
`;

export const NavbarMenuButton = styled(Menu)`
  && {
    display: none;
    color: ${COLORS.gray};
    cursor: pointer;

    &:hover {
      color: ${COLORS.blueLuckyPoint};
    }

    @media (max-width: ${SIZES.bigTablet}px) {
      display: block;
    }
  }
`;

export const NavbarItemsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    display: none;
  }
`;

export const NavbarItem = styled(NavLink)`
  && {
    padding: 10px 15px;
    color: ${COLORS.blueLuckyPoint};
    font-family: 'Inter', Helvetica, Arial, serif;

    &.active {
      color: ${COLORS.pinkCarnation};
      font-weight: 600;
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    span {
      font-size: 16px;
    }

    &:hover {
      color: ${COLORS.pinkCarnation};
    }
  }
`;
