export const SIZES = {
  smallPhone: 320,
  smallMediumPhone: 400,
  mediumPhone: 480,
  bigPhone: 575,
  smallTablet: 600,
  mediumTablet: 767,
  muiTableChange: 900,
  bigTablet: 991,
  smallDesktop: 1024,
  desktop: 1199,
  bigDesktop: 1366,
};
