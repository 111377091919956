import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'NAVBAR', {
  BLOG: 'Blog',
  CONTACT: 'Contact',
  FAQ: 'FAQ',
  SERVICES: 'Services',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'NAVBAR', {
  BLOG: 'Blog',
  CONTACT: 'Contacto',
  FAQ: 'FAQ',
  SERVICES: 'Servicios',
});
