import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetAll, onGetOne } from 'redux/actions/blogs.actions';
import { blogSelector, blogsSelector } from 'redux/selectors/blogs.selector';

import LayoutContainer from 'containers/LayoutContainer';
import BlogView from 'views/BlogView';

import 'views/BlogView/i18n';

const BlogContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const { loading } = useTypedSelector((state) => state.blogs);
  const item = useTypedSelector(blogSelector);
  const items = useTypedSelector(blogsSelector);

  useEffect(() => {
    dispatch(onGetAll(id));
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  return (
    <LayoutContainer title={i18next.t<string>('BLOG_VIEW:TITLE')}>
      <BlogView item={item} items={items} loading={loading} />
    </LayoutContainer>
  );
};

export default BlogContainer;
