import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'SERVICES_VIEW', {
  TITLE: 'Services',
  WHAT_WE_OFFER_TITLE: 'What we offer?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'SERVICES_VIEW', {
  TITLE: 'Servicios',
  WHAT_WE_OFFER_TITLE: '¿Qué ofrecemos?',
});
