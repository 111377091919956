import { createGlobalStyle } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${COLORS.white} !important;
    font-family: 'Space Grotesk', Helvetica, Arial, serif !important;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  #root {
    height: 100%;
  }
  
  .notification-container {
    .message {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
