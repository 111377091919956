import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import i18next from 'i18next';

import { ViewContainer, Title } from 'styles/view';

import { IFAQViewProps } from './types';
import {
  FAQViewContent,
  FAQViewImageContainer,
  FAQViewImage,
  FAQViewQuestionsContainer,
  FAQViewQuestionContainer,
  FAQViewQuestionContent,
  FAQViewQuestionDescription,
} from './styles';
import './i18n';

const FAQView: FunctionComponent<IFAQViewProps> = ({ items }: IFAQViewProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ViewContainer data-testid="faq-view">
      <FAQViewContent>
        <FAQViewImageContainer>
          <Title>{i18next.t<string>('FAQ_VIEW:TITLE')}</Title>
          <FAQViewImage />
        </FAQViewImageContainer>
        <FAQViewQuestionsContainer>
          {items.map(({ description, key, title }) => (
            <FAQViewQuestionContainer
              expanded={expanded === key}
              key={key}
              onChange={handleChange(key)}
            >
              <FAQViewQuestionContent expanded={expanded === key} expandIcon={<ExpandMore />}>
                {title}
              </FAQViewQuestionContent>
              <FAQViewQuestionDescription dangerouslySetInnerHTML={{ __html: description }} />
            </FAQViewQuestionContainer>
          ))}
        </FAQViewQuestionsContainer>
      </FAQViewContent>
    </ViewContainer>
  );
};

export default FAQView;
