import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ViewContent } from 'styles/view';

export const KnowOurArticlesContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
`;

export const KnowOurArticlesTitle = styled.span`
  margin-bottom: 50px;
  color: ${COLORS.pinkCarnation};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
`;

export const KnowOurArticlesLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`;

export const ExploreMoreContentContainer = styled(ViewContent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const ExploreMoreContentTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
`;

export const ExploreMoreContentBlogContainer = styled.div`
  position: relative;
  margin-top: 50px;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0) 120px);
    pointer-events: none;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    :after {
      content: none;
    }
  }
`;
