import { Reducer } from 'redux';

import { IAppState } from 'types/app.types';
import * as constants from 'redux/constants/app.constants';
import { SIZES } from 'helpers/enums/sizes';

export const defaultState: IAppState = {
  blogs: [],
  isContactFormSucceeded: false,
  isMenuOpened: window.innerWidth >= SIZES.bigTablet,
  loading: false,
};

const appReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.APP_ON_CONTACT_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.APP_ON_TOGGLE_MENU_REQUESTED:
      return {
        ...state,
        isMenuOpened: action.isMenuOpened,
      };
    case constants.APP_ON_CONTACT_SUCCEEDED:
      return {
        ...state,
        isContactFormSucceeded: true,
        loading: false,
      };
    case constants.APP_ON_GET_LAST_BLOGS_SUCCEEDED:
      return {
        ...state,
        blogs: data.items,
      };
    case constants.APP_ON_CONTACT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.APP_ON_CONTACT_RESET:
      return {
        ...state,
        isContactFormSucceeded: false,
      };
    default:
      return state;
  }
};

export default appReducer;
