import styled from 'styled-components';

export const WhatsappContainer = styled.a`
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 4;

  &:hover {
    opacity: 0.5;
  }
`;

export const WhatsappImage = styled.img`
  width: 40px;
  height: 40px;
`;
