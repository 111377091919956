import React, { FunctionComponent } from 'react';

import Logo from 'components/commons/Logo';

import { INavbarProps } from './types';
import {
  NavbarContainer,
  NavbarContent,
  NavbarMenuButton,
  NavbarItemsContainer,
  NavbarItem,
} from './styles';

const Navbar: FunctionComponent<INavbarProps> = (props: INavbarProps) => {
  const { items, onOpenMenu } = props;

  return (
    <NavbarContainer data-testid="navbar">
      <NavbarContent>
        <Logo />
        <NavbarMenuButton data-testid="navbar-menu-button" onClick={onOpenMenu} />
        <NavbarItemsContainer>
          {items.map(({ key, label, to }) => (
            <li key={key}>
              <NavbarItem activeClassName="active" data-testid={`nabvar-item-${key}`} to={to}>
                <span>{label}</span>
              </NavbarItem>
            </li>
          ))}
        </NavbarItemsContainer>
      </NavbarContent>
    </NavbarContainer>
  );
};

export default Navbar;
