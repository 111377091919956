import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'ERROR_VIEW', {
  TITLE: 'Page not found',
  BUTTON: 'Go to home',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'ERROR_VIEW', {
  TITLE: 'Página no encontrada',
  BUTTON: 'Volver al inicio',
});
