import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { history } from 'helpers/history';

import Loading from 'components/commons/Loading';
import Author from 'components/commons/Author';
import Carousel from 'components/commons/Carousel';
import BlogPreview from 'components/commons/BlogPreview';

import { IBlog } from 'types/blogs.types';
import { ViewContainer } from 'styles/view';

import { IBlogViewProps } from './types';
import {
  BlogViewContainer,
  BlogViewArrowBack,
  BlogViewContent,
  BlogViewLoadingContainer,
  BlogViewTitle,
  BlogViewIntroduction,
  BlogViewImage,
  BlogViewDescription,
  BlogViewExploreMoreContentContainer,
  BlogViewExploreMoreContentTitle,
  BlogViewExploreMoreContentBlogContainer,
} from './styles';
import './i18n';

const BlogView: FunctionComponent<IBlogViewProps> = (props: IBlogViewProps) => {
  const { item, items, loading } = props;

  return (
    <ViewContainer data-testid="blogs-view">
      <BlogViewContainer>
        <BlogViewArrowBack onClick={history.goBack} />
        <BlogViewContent>
          {loading ? (
            <BlogViewLoadingContainer>
              <Loading />
            </BlogViewLoadingContainer>
          ) : (
            item && (
              <>
                <Author date={item.createdAt} name={item.author} />
                <BlogViewTitle>{item.title}</BlogViewTitle>
                <BlogViewIntroduction>{item.introduction}</BlogViewIntroduction>
                <BlogViewImage src={item.image} />
                <BlogViewDescription dangerouslySetInnerHTML={{ __html: item.description }} />
              </>
            )
          )}
        </BlogViewContent>
        <BlogViewExploreMoreContentContainer>
          <BlogViewExploreMoreContentTitle>
            {i18next.t<string>('BLOG_VIEW:EXPLORE_MORE_CONTENT_TITLE')}
          </BlogViewExploreMoreContentTitle>
          <BlogViewExploreMoreContentBlogContainer>
            <Carousel
              autoPlay
              items={items}
              renderItem={(blog: IBlog) => <BlogPreview item={blog} />}
            />
          </BlogViewExploreMoreContentBlogContainer>
        </BlogViewExploreMoreContentContainer>
      </BlogViewContainer>
    </ViewContainer>
  );
};

export default BlogView;
