import styled from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';

import Button from 'components/commons/Button';

export const FormContent = styled.form`
  width: 100%;
`;

export const FormRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: ${SIZES.bigDesktop}px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const FormSubmitButton = styled(Button)`
  && {
    width: 200px;
    padding: 20px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
    line-height: 19px;
  }
`;
