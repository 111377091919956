import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';

import { ReactComponent as Logo } from 'components/commons/Logo/assets/logo.svg';

export const LogoLink = styled(Link)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${COLORS.blueLuckyPoint};
    font-size: 20px;

    span {
      margin-left: 10px;
      font-weight: 700;
    }
  }
`;

export const LogoImage = styled(Logo)`
  && {
    height: 34px;

    &.outlined {
      width: 116px;
      height: 26px;
      filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(342deg) brightness(117%) contrast(100%);
    }

    &:active,
    &:hover {
      filter: invert(67%) sepia(74%) saturate(6088%) hue-rotate(326deg) brightness(101%)
        contrast(101%);
    }
  }
`;
