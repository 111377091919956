import React, { FunctionComponent } from 'react';

import Logo from 'components/commons/Logo';

import { ISidebarProps } from './types';
import {
  SidebarContainer,
  SidebarContent,
  SidebarToggleMenu,
  SidebarCloseButton,
  SidebarItem,
  SidebarOverlay,
} from './styles';

const Sidebar: FunctionComponent<ISidebarProps> = (props: ISidebarProps) => {
  const { isMenuOpened, items, onCloseMenu } = props;

  return (
    <div data-testid="sidebar">
      <SidebarContainer open={isMenuOpened}>
        <SidebarContent>
          <SidebarToggleMenu key="menu">
            <Logo />
            <SidebarCloseButton data-testid="sidebar-close-button" onClick={onCloseMenu} />
          </SidebarToggleMenu>
          {items.map(({ Icon, key, label, to }) => (
            <li key={key}>
              <SidebarItem
                activeClassName="active"
                data-testid={`sidebar-item-${key}`}
                open={isMenuOpened}
                to={to}
              >
                {Icon && <Icon />}
                <span>{label}</span>
              </SidebarItem>
            </li>
          ))}
        </SidebarContent>
      </SidebarContainer>
      {isMenuOpened && <SidebarOverlay data-testid="sidebar-overlay" onClick={onCloseMenu} />}
    </div>
  );
};

export default Sidebar;
