import { AnyAction } from 'redux';

import * as constants from 'redux/constants/blogs.constants';

export const onGetAll = (id?: string): AnyAction => ({
  type: constants.BLOGS_ON_GET_ALL_REQUESTED,
  id,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.BLOGS_ON_GET_ONE_REQUESTED,
  id,
});
