import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as constants from 'redux/constants/services.constants';
import { getAll } from 'services/services.services';

export function* servicesGetAll(): Generator {
  try {
    const { data }: any = yield call(getAll);
    yield put({ type: constants.SERVICES_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.SERVICES_ON_GET_ALL_FAILED, error });
  }
}

export function* watchServices(): Generator {
  yield all([takeLatest(constants.SERVICES_ON_GET_ALL_REQUESTED, servicesGetAll)]);
}
