import React, { FunctionComponent } from 'react';

import { ILogoProps } from './types';
import { LogoLink, LogoImage } from './styles';

const Logo: FunctionComponent<ILogoProps> = ({
  className = '',
  variant = 'contained',
}: ILogoProps) => (
  <LogoLink data-testid="logo" to="/">
    <LogoImage className={`${className} ${variant}`} />
  </LogoLink>
);

export default Logo;
