import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ReactComponent as EmailSent } from 'views/ContactView/assets/email-sent.svg';

import { ViewContainer, ViewContent } from 'styles/view';
import { FormButtonContainer } from 'styles/form';

export const ContactViewContainer = styled(ViewContainer)`
  background: ${COLORS.white};
  ${({ isView }: { isView: boolean }) =>
    isView &&
    css`
      padding: 0 20px;
      background: ${COLORS.grayAlabaster};
    `}
`;

export const ContactViewContent = styled(ViewContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 100px 0;

  ${({ isView }: { isView: boolean }) =>
    isView &&
    css`
      align-items: center;
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContactViewPropertyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  height: 100%;

  @media (max-width: ${SIZES.bigTablet}px) {
    align-items: center;
    max-width: 90%;
    text-align: center;
  }
`;

export const ContactViewPropertyTitle = styled.span`
  color: ${COLORS.blueLuckyPoint};
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
`;

export const ContactViewPropertySubtitle = styled.span`
  margin-top: 10px;
  color: ${COLORS.pinkCarnation};
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-top: 35px;
  }
`;

export const ContactViewPropertyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ContactViewPropertyInfoText = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-top: 15px;
  color: ${COLORS.blueLuckyPoint};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-weight: 600;
  line-height: 19px;
  font-size: 16px;
  vertical-align: middle;

  svg {
    margin-right: 8px;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    justify-content: center;
  }
`;

export const ContactViewFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isView }: { isView: boolean }) =>
    isView
      ? css`
          width: 415px;
          padding: 43px 48px;
          background: ${COLORS.white};
          box-shadow: ${COLORS.boxShadow};
          border-radius: 20px;
        `
      : css`
          width: 600px;

          @media (max-width: ${SIZES.bigDesktop}px) {
            width: 415px;
          }
        `}

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 90%;
    margin-top: 50px;
  }
`;

export const ContactViewFormTitle = styled.span`
  margin-bottom: 35px;
  color: ${COLORS.blueRibbon};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
`;

export const ContactViewFormButtonContainer = styled(FormButtonContainer)`
  margin-top: 30px;
  ${({ isView }: { isView: boolean }) =>
    !isView &&
    css`
      align-items: flex-start;

      @media (max-width: ${SIZES.bigTablet}px) {
        align-items: center;
      }
    `}
`;

export const ContactViewModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 300px;
  height: 158px;
  margin: 0 -10px -10px 0;

  @media (max-width: ${SIZES.mediumPhone}px) {
    width: 260px;
  }
`;

export const ContactViewModalTitle = styled.span`
  display: inline-block;
  width: 126px;
  margin: -5px 0px 0px 15px;
  color: ${COLORS.blueLuckyPoint};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  text-align: left;

  @media (max-width: ${SIZES.mediumPhone}px) {
    width: 110px;
  }
`;

export const ContactViewModalImage = styled(EmailSent)`
  position: absolute;
  top: -15px;
  right: 0;
`;
