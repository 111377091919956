import styled, { css } from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';

export const CarouselContainer = styled.div`
  width: 100%;

  .alice-carousel {
    width: ${({ autoPlay }: { autoPlay?: boolean }) => (autoPlay ? '100%' : 'calc(100% - 28px)')};
  }
`;

export const CarouselArrow = css`
  position: absolute;
  top: 50%;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  transform: translateY(-50%);
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

export const CarouselLeftArrow = styled.button`
  ${CarouselArrow}
  left: -30px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    left: 0;
  }
`;

export const CarouselRightArrow = styled.button`
  ${CarouselArrow}
  right: -30px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    right: 0;
  }
`;
