import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import LayoutContainer from 'containers/LayoutContainer';
import UsView from 'views/UsView';

import 'views/UsView/i18n';

const UsContainer: FunctionComponent = () => (
  <LayoutContainer title={i18next.t<string>('US_VIEW:TITLE')}>
    <UsView />
  </LayoutContainer>
);

export default UsContainer;
