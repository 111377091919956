import { createSelector } from 'reselect';

import { ASSETS_URL } from 'configs/configs';

import { IServicesState } from 'types/services.types';

const getItems = (state: { services: IServicesState }) => state.services.items;

export const servicesSelector = createSelector([getItems], (services) =>
  services.map((service) => ({
    ...service,
    icon: ASSETS_URL + service.icon,
    image: ASSETS_URL + service.image,
  })),
);
