import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { FOOTER_HEIGHT } from 'helpers/dimensions';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ViewContent } from 'styles/view';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  padding: 20px;
  background-color: ${COLORS.blueLuckyPoint};

  @media (max-width: ${SIZES.mediumTablet}px) {
    height: auto;
  }
`;

export const FooterContent = styled(ViewContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const FooterLogoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.mediumTablet}px) {
    align-items: center;
  }
`;

export const FooterCopyright = styled.span`
  margin-top: 15px;
  color: ${COLORS.grayAlabaster};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 45px;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-top: 10px;
  }
`;

export const FooterLink = styled(Link)`
  && {
    padding: 0 10px;
    color: ${COLORS.grayAlabaster};
    font-family: 'Inter', Helvetica, Arial, serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    border-right: 0.5px solid ${COLORS.grayManatee};

    :first-child {
      padding-left: 0;
    }

    :last-child {
      border-right: none;
    }

    @media (max-width: ${SIZES.mediumTablet}px) {
      :last-child {
        padding-right: 0;
      }
    }
  }
`;

export const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const FooterInfoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 80px;

  :last-child {
    margin-right: 0;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    :last-child {
      margin-top: 20px;
    }
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    margin: 20px 0 0;
  }
`;

export const FooterInfoSectionTitle = styled.span`
  margin-bottom: 20px;
  color: ${COLORS.white};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-bottom: 4px;
  }
`;

export const FooterInfoSectionText = styled.a`
  margin-top: 6px;
  color: ${COLORS.grayAlabaster};
  font-family: 'Inter', Helvetica, Arial, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  ${({ important }: { important?: boolean; underlined?: boolean }) =>
    important &&
    css`
      color: ${COLORS.pinkCarnation};
    `}

  ${({ underlined }: { important?: boolean; underlined?: boolean }) =>
    underlined &&
    css`
      text-decoration: underline;
    `}
`;
